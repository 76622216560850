import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import { useState, useEffect } from "react";
import Footer from "./components/Footer";
import Service from "./pages/Service";
import Contact from "./pages/Contact";
import Course from "./pages/Course";
import About from "./pages/About";
import HomePage from "./pages/HomePage";
import Blog from "./pages/Blog";
import Login from "./components/Login";
import Register from "./components/Register";
import ColorMode from "./utils/ColorMode";
import SingleCoursePage from "./components/SingleCoursePage";
import Video from "./pages/admin panel/Video";
import Profile from "./pages/Profile";
import ForgotPassword from "./components/ForgotPassword";
import ChangePassword from "./components/ChangePassword";
import VerifySucces from "./components/VerifySuccess";
import Lectures from "./pages/admin panel/Lectures";
import Users from "./pages/admin panel/Users";
import Email from "./components/Email";
import UdyamSheel from "./pages/UdyamSheel";
import UdyamSathi from "./pages/UdyamSathi";
import RequestPage from "./pages/RequestPage";
import HomeNavabar from "./components/HomeNavbar";
import CampusLogin from "./components/CampusLogin.jsx";
import Privacy from "./pages/Privacy";
import TermsCondition from "./pages/TermsCondition";
import SellOnONDC from "./pages/sellOnONDC";
import ResponsePage from "./components/ResponsePage";
import UdyamDashboard from "./pages/UdyamDashboard";
import VideoUpload from "./components/videoUpload";
import VisitorsPage from "./pages/admin panel/VisitorsPage.jsx";
import WhatsAppPopup from "./pages/whatsapppopup";
import ReactGA from "react-ga4";
import i18n from "./i18n.js";
import SellOnONDCForm from "./components/forms/SellOnONDCform.jsx";
import CampuspreneurForm from "./components/forms/CampuspreneurForm.jsx";
import CampusAdmin from "./components/CampusAdmin.jsx";
import AOS from "aos";
import "aos/dist/aos.css";

function App() {
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  const TrackingId = "G-0BT46G4GM2";
  const [selectedLanguage, setSelectedLanguage] = useState("en");

  const changeLanguage = (language) => {
    setSelectedLanguage(language);
    i18n.changeLanguage(language); // Ensure i18n language change is handled
  };

  ReactGA.initialize(TrackingId);

  const location = useLocation();
  const hideFooter =
    location.pathname === "/campusprenuerdashboard" ||
    location.pathname === "/login" ||
    location.pathname === "/register";

  return (
    <>
      <div>
        <ColorMode>
          <WhatsAppPopup
            selectedLanguage={selectedLanguage}
            changeLanguage={changeLanguage}
          />
          <HomeNavabar
            selectedLanguage={selectedLanguage}
            changeLanguage={changeLanguage}
          />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/campuslogin" element={<CampusLogin />} />
            <Route path="/about" element={<About />} />
            <Route path="/blogs" element={<Blog />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/courses" element={<Course />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Email />} />
            <Route path="/register/:otp" element={<Register />} />
            <Route path="/services" element={<Service />} />
            <Route path="/course/:id" element={<SingleCoursePage />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/change-password/:id" element={<ChangePassword />} />
            <Route path="/verify-success/:id" element={<VerifySucces />} />
            <Route path="/udyamsheel" element={<UdyamSheel />} />
            <Route path="/sell-on-ondc" element={<SellOnONDC />} />
            <Route path="/sell-on-ondc-form" element={<SellOnONDCForm />} />
            <Route path="/campuspreneur" element={<UdyamSathi />} />
            <Route path="/campuspreneur-form" element={<CampuspreneurForm />} />
            <Route
              path="/campusprenuerdashboard"
              element={<UdyamDashboard />}
            />
            <Route path="/requestpage" element={<RequestPage />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/termscondition" element={<TermsCondition />} />
            <Route path="/videoupload" element={<VideoUpload />} />
            <Route path="/admin">
              <Route path="courses" element={<Video />} />
              <Route path="course/:id" element={<Lectures />} />
              <Route path="users" element={<Users />} />
              <Route path="responses" element={<ResponsePage />} />
              <Route path="visitors" element={<VisitorsPage />} />
              <Route path="campuspreneuradmin" element={<CampusAdmin />} />
            </Route>
          </Routes>
          {!hideFooter && <Footer />}
        </ColorMode>
      </div>
    </>
  );
}

export default App;