import React, { useRef, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import banner_img from "../assets/banner_img.webp";
import { landingPageBg, learning_about } from "../assets";
import ondc from "../assets/ondc.webp";
import sbi from "../assets/sbifoundationlogo.webp";
import microsoft from "../assets/microsoft.webp";
import iitmandicatalyst from "../assets/iitmandicatalyst.webp";
import learning_about2 from "../assets/learning_about2.png";
import learning_about3 from "../assets/learning_about3.png";
import blog1 from "../assets/blog1.jpeg";
import featureIcon from "../assets/featureICON.png";
import { AboutServices } from "./About";
import { useNavigate } from "react-router";
import { Button } from "@mui/material";
// import AnimatedCircles from "../components/AnimatedCircles";
import "./homepage.css";
import ReactGA from "react-ga4";

const HomePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Create a ref for the countBox component
  const countBoxRef = useRef(null);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const countBox = countBoxRef.current;
  //     const scrollY = window.scrollY;

  //     if (countBox && scrollY >= 450) {
  //       countBox.style.transform = "TranslateY(50px)";
  //     } else {
  //       countBox.style.transform = "TranslateY(-100px)";
  //     }
  //   };

  //   // Add the scroll event listener when the component mounts
  //   window.addEventListener("scroll", handleScroll);

  //   // Remove the scroll event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/" });
  }, []);

  return (
    <>
      <div className="landingPageCover">
        <img
          src={landingPageBg}
          width="100vw"
          alt="landing"
          className="landingPageCover__animation"
        />
      </div>
      <div className="HomeContainer">
        <div
          className="banner"
          style={{
            justifyContent: "center",
            alignItems: "center",
            marginTop: "6rem",
            width: "80%",
          }}
          data-aos="fade-up"
        >
          <div className="bannerText">
            <h1 className="home-head">
              {""}
              {t("body_main")}
            </h1>
            <p style={{ textAlign: "justify" }}>
              {/* {" "} */}
              {t("body_home")}
            </p>
            <div className="homeButtons">
              <Button
                variant="contained"
                sx={{
                  color: "white",
                  padding: "10px 60px",
                  font: "Poppins,sans-serif",
                  fontWeight: "500",
                  textTransform: "inherit",
                  border: "none",
                  background:
                    "linear-gradient(90deg, rgba(46,132,70,1) 0%, rgba(0,100,0,1) 100%);",
                }}
                onClick={() => {
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.udyamwell.seller.app&hl=en_US",
                    "_blank"
                  );
                }}
              >
                {t("sellonondc_btn")}
              </Button>

              <Button
                sx={{
                  p: "10px 20px",
                  borderRadius: "2px",
                  border: "solid 1.5px",
                  ml: 3,
                  font: "Poppins,sans-serif",
                  fontWeight: "500",
                  color: "#006400",
                  textTransform: "initial",
                  transition:
                    "filter 0.4s ease, color 0.4s ease, background 0.3s ease",
                  "&:hover": {
                    filter: "drop-shadow(-2px 4px 4px   #0064003a  )",
                    color: "white",
                    background: "linear-gradient( #2E8446 , #006400 )",
                  },
                }}
                variant="outlined"
                onClick={() => navigate("/udyamsheel")}
              >
                {t("udyamsheel_btn")}
              </Button>
            </div>
          </div>
          <div className="bannerImg">
            <img src={banner_img} alt="" />
          </div>
        </div>
      </div>
      {/* section 2 */}
      <Box ref={countBoxRef} className="countBox" data-aos="fade-up">
        <Box className="count">
          <Typography
            variant="h3"
            style={{
              paddingTop: "15px",
              color: "#236836",
              fontSize: "32px",
              fontWeight: "600",
              fontFamily: "Poppins,sans-serif",
            }}
          >
            {t("supported_by")}
          </Typography>
        </Box>
        <Box className="count">
          <img src={ondc} alt="ONDC" />

          <img src={sbi} alt="SBI Foundation" />

          <img src={microsoft} alt="Microsoft for Startups" />
          <img src={iitmandicatalyst} alt="IIT Mandi Catalyst" />
        </Box>
      </Box>
      {/* section 3 */}
      <div className="homeServices" style={{ width: "80%" }} data-aos="fade-up">
        <AboutServices />
      </div>

      {/*  section3*/}
      <div className="homeAbout">
        <div className="aboutImg" data-aos="fade-up">
          <img className="drop1" src={learning_about} alt="" />
        </div>
        <div className="aboutInfo" data-aos="fade-up">
          {/* <div className="aboutSub">
            <div className="about_"></div>
            <div className="aboutSubText" style={{ marginLeft: "10px" }}>
              <p>{t("Aboutus")}</p>
            </div>
          </div> */}
          {/*  */}
          <h3 style={{ lineHeight: "40px" }}>{t("Aboutus_head")}</h3>
          <div className="aboutKeyPoints">
            <p>{t("Aboutus_body")} </p>
          </div>
          {/*  */}
          <div className="homeAboutButton">
            <button
              style={{ cursor: "pointer", borderRadius: "10px" }}
              onClick={() => {
                window.open(
                  "https://play.google.com/store/apps/details?id=com.udyamwell.seller.app&hl=en_US",
                  "_blank"
                );
              }}
            >
              {t("start-selling")}
            </button>
          </div>
        </div>
      </div>
      <div className="homeAbout2">
        <div className="aboutImg" data-aos="fade-up">
          <img src={learning_about2} alt="" />
        </div>
        <div className="aboutInfo2" data-aos="fade-up">
          {/* <div className="aboutSub">
            <div className="about_"></div>
            <div className="aboutSubText" style={{ marginLeft: "10px" }}>
              <p>{t("AdvanceFeature")}</p>
            </div>
          </div> */}
          {/*  */}
          <h3 style={{ lineHeight: "40px" }}>
            {t("udyamsheel_btn")}: {t("Aboutus_head2")}
          </h3>
          <div className="aboutKeyPoints">
            <p>{t("Aboutus_body2")} </p>
          </div>
          {/*  */}
          <div className="homeAboutfeatures">
            <div className="featurebottom">
              <div className="feature-icon">
                <img src={featureIcon} alt=" " />
              </div>
              <div className="feature-head">
                <p
                  style={{
                    fontSize: "18px",
                    marginTop: "none",
                  }}
                >
                  <span style={{ fontWeight: "600" }}>
                    {t("feature-head1")} :
                  </span>{" "}
                  {t("feature-body1")}
                </p>
              </div>
            </div>
            <div className="featurebottom">
              <div className="feature-icon">
                <img src={featureIcon} alt=" " />
              </div>
              <div className="feature-head">
                <p
                  style={{
                    fontSize: "18px",
                    marginTop: "none",
                  }}
                >
                  <span style={{ fontWeight: "600" }}>
                    {t("feature-head2")} :
                  </span>{" "}
                  {t("feature-body2")}
                </p>
              </div>
              {/* <div className="feature-body">
                <p>{t("feature-body2")}</p>
              </div> */}
            </div>
            <div className="featurebottom">
              <div className="feature-icon">
                <img src={featureIcon} alt=" " />
              </div>
              <div className="feature-head">
                <p
                  style={{
                    fontSize: "18px",
                    marginTop: "none",
                  }}
                >
                  <span style={{ fontWeight: "600" }}>
                    {t("feature-head3")} :
                  </span>{" "}
                  {t("feature-body3")}
                </p>
              </div>
              {/* <div className="feature-body">
                <p>{t("feature-body2")}</p>
              </div> */}
            </div>
            <div className="homeAboutButton">
              <button
                style={{ cursor: "pointer", borderRadius: "10px" }}
                onClick={() => {
                  navigate("/udyamsheel");
                }}
              >
                {t("readmore_btn")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="homeAbout">
        <div className="aboutImg" data-aos="fade-up">
          <img className="drop3" src={learning_about3} alt="" />
        </div>
        <div className="aboutInfo" data-aos="fade-up">
          <h3 style={{ lineHeight: "40px" }}>{t("Aboutus_head3")}</h3>
          <div className="aboutKeyPoints">
            <p>{t("Aboutus_body3")} </p>
          </div>
          <div className="homeAboutfeatures">
            <div className="featurebottom">
              <div className="feature-icon">
                <img src={featureIcon} alt=" " />
              </div>
              <div className="feature-head">
                <p
                  style={{
                    fontSize: "18px",
                    marginTop: "none",
                  }}
                >
                  <span style={{ fontWeight: "600" }}>
                    {t("feature2-head1")} :
                  </span>{" "}
                  {t("feature2-body1")}
                </p>
              </div>
            </div>
            <div className="featurebottom">
              <div className="feature-icon">
                <img src={featureIcon} alt=" " />
              </div>
              <div className="feature-head">
                <p
                  style={{
                    fontSize: "18px",
                    marginTop: "none",
                  }}
                >
                  <span style={{ fontWeight: "600" }}>
                    {t("feature2-head2")} :
                  </span>{" "}
                  {t("feature2-body2")}
                </p>
              </div>
              {/* <div className="feature-body">
                <p>{t("feature-body2")}</p>
              </div> */}
            </div>
            <div className="featurebottom">
              <div className="feature-icon">
                <img src={featureIcon} alt=" " />
              </div>
              <div className="feature-head">
                <p
                  style={{
                    fontSize: "18px",
                    marginTop: "none",
                  }}
                >
                  <span style={{ fontWeight: "600" }}>
                    {t("feature2-head3")} :
                  </span>{" "}
                  {t("feature2-body3")}
                </p>
              </div>
              {/* <div className="feature-body">
                <p>{t("feature-body2")}</p>
              </div> */}
            </div>

            <div className="homeAboutButton">
              <button
                style={{ cursor: "pointer", borderRadius: "10px" }}
                onClick={() => {
                  navigate("/campuspreneur");
                }}
              >
                {t("readmore_btn")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <Box
        sx={{ p: "0 55px" }}
        textAlign={"center"}
        width={"auto"}
        display={"flex"}
        flexDirection={"column"}
        gap={"20px"}
        justifyContent={"center"}
        alignItems={"center"}
        data-aos="fade-up"
      >
        <h1 className="Text_Ondch1">
          <span style={{ color: "black" }}>Latest Insights for </span>
          Bharatpreneurs
        </h1>
        <Typography
          variant="h6"
          fontWeight={"500"}
          fontSize={"16px"}
          className="serviceSubHeading"
          textAlign={"center"}
          marginTop={"0rem"}
          color={"black"}
          fontFamily={"Poppins, sans-serif"}
          width={"100%"}
        >
          {" "}
          See for yourself how our platform can help you achieve your digital
          business goals and innovate in your digital experiences.
        </Typography>
        <div
          className="card-section"
          data-aos="fade-up"
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "40px",
          }}
        >
          <div
            style={{
              border: "1px solid #ccc",
              borderRadius: "8px",
              overflow: "hidden",
              maxWidth: "380px",
              margin: "16px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <img
              src={blog1}
              alt="Card"
              style={{
                width: "90%",
                height: "auto",
                margin: "20px auto 0px auto",
              }}
            />
            <div style={{ padding: "16px" }}>
              <p style={{ marginBottom: "16px", textAlign: "justify" }}>
                {" "}
                Udyamwell showcases inspiring stories of rural entrepreneurs,
                'Bharatpreneurs,' who are transforming India's villages with
                innovation, blending tradition with modernity, and empowering
                local economies.
              </p>
              <Button
                sx={{
                  color: "white",
                  padding: "10px 60px",
                  marginBottom: "10px",
                  font: "Poppins,sans-serif",
                  fontWeight: "500",
                  textTransform: "inherit",
                  border: "none",
                  background:
                    "linear-gradient(90deg, rgba(46,132,70,1) 0%, rgba(0,100,0,1) 100%);",
                }}
                onClick={() => console.log("Read more clicked!")}
              >
                Read More &rarr;
              </Button>
            </div>
          </div>
        </div>
      </Box>
    </>
  );
};
export default HomePage;