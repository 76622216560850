import React, { useEffect, useState } from "react";
import "./contact.css";

import Bg_login from "../assets/Bg_login.webp";
import emailjs from "@emailjs/browser";
import { useRef } from "react";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { Button, Stack, TextField } from "@mui/material";
const Contact = () => {
  const { t } = useTranslation();
  const [wrong] = useState("");
  const form = useRef();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  // const Service_id = import.meta.env.Service_id;
  const Template_id = process.env.Template_id;
  // const [name,setName] = useState("");
  // const [email,setEmail] = useState("");
  // const [subject,setSubject] = useState("");
  // const [message,setMessage] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_vv4ze4b",
        "template_a1xm98n",
        form.current, // Use the ref for the form
        "e7EHTp-Nfbp7jKueD"
      )
      .then(
        (result) => {
          Swal.fire(
            `Email Sent`,
            "Please wait for the team to reply!!",
            "success"
          );
          window.location.reload();
          // console.log(result.text);
        },
        (error) => {
          alert(error.text);
        }
      );
  };

  return (
    <>
      {/* <TopSection MainHeadinig="Contact Us" subText="" text="Contact Us" /> */}
      <div className="landingPageCover">
        <img
          src={Bg_login}
          width="100vw"
          alt="landing"
          className="landingPageCover__animation"
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
          marginTop: "8rem",
        }}
      >
        {" "}
        <div className="contactMain" style={{ marginTop: "2rem" }}>
          {/* <form ref={form} onSubmit={handleSubmit}>
                <Stack className="ContactStack" spacing={2}>
                  <TextField
                    fullWidth
                    name="from_name"
                    variant="outlined"
                    type="text"
                    placeholder={t("formph_name")}
                    sx={{
                      backgroundColor: "#E0F1E5",
                      "& .MuiOutlinedInput-input::placeholder": {
                        color: "black", // Placeholder color
                        fontWeight: "400",
                      },
                    }}
                    // value={name}
                  />
                  <TextField
                    variant="outlined"
                    type="email"
                    placeholder={t("formph_mail")}
                    name="email"
                    sx={{
                      backgroundColor: "#E0F1E5",
                      "& .MuiOutlinedInput-input::placeholder": {
                        color: "black", // Placeholder color
                        fontWeight: "400",
                      },
                    }}
                    // value={email}
                    // onChange={(e) => setEmail(e.target.value)}
                  />
                  <TextField
                    variant="outlined"
                    type="text"
                    name="subject"
                    placeholder={t("formph_subject")}
                    sx={{
                      backgroundColor: "#E0F1E5",
                      "& .MuiOutlinedInput-input::placeholder": {
                        color: "black", // Placeholder color
                        fontWeight: "400",
                      },
                    }}
                    // value={subject}
                    // onChange={(e) => setSubject(e.target.value)}
                  />
                  <TextField
                    // sx={{borderBottom:"1px solid black",mt:5}}
                    placeholder={t("formph_message")}
                    variant="outlined"
                    name="message"
                    sx={{
                      backgroundColor: "#E0F1E5",
                      "& .MuiOutlinedInput-input::placeholder": {
                        color: "black", // Placeholder color
                        fontWeight: "400",
                      },
                    }}
                    
                  />
                </Stack>
                <Button
                  variant="contained"
                  type="submit"
                  sx={{
                    margin: "28px 28%",
                    color: "white",
                    padding: "10px 60px",
                    borderRadius: "20px",
                    fontWeight: "400",
                    textTransform: "inherit",
                    border: "none",
                    background:
                      "linear-gradient(90deg, rgba(46,132,70,1) 0%, rgba(0,100,0,1) 100%);",
                  }}
                >
                  {t("submit_btn")}
                </Button>
              </form> */}
          <form ref={form} onSubmit={handleSubmit} data-aos="fade-left">
            <h1
              className="subHeading"
              style={{
                color: "#046A3A",
                fontFamily: "Poppins,sans-serif",
                fontWeight: "00",
                fontSize: "50px",
                textAlign: "center",
                paddingTop: "20px",
              }}
            >
              Get In <span style={{ color: "black" }}>Touch</span>
            </h1>
            {wrong && <h3>{wrong}</h3>}

            <div className="inputs">
              <Stack
                alignSelf={"center"}
                sx={{ width: "90%", margin: "1rem auto" }}
                spacing={2}
              >
                <TextField
                  fullWidth
                  sx={{
                    width: "100%",
                    backgroundColor: "#E0F1E5",
                    "& .MuiOutlinedInput-input::placeholder": {
                      color: "black",
                      fontWeight: "400",
                      alignSelf: "center",
                    },
                  }}
                  type="text"
                  name="from_name"
                  placeholder="Name"
                  label={t("formph_name")}
                  required
                />

                <TextField
                  fullWidth
                  sx={{
                    width: "100%",
                    backgroundColor: "#E0F1E5",
                    "& .MuiOutlinedInput-input::placeholder": {
                      color: "black",
                      fontWeight: "400",
                      alignSelf: "center",
                    },
                  }}
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  label={t("formph_mail")}
                  required
                />

                <TextField
                  fullWidth
                  sx={{
                    width: "100%",
                    backgroundColor: "#E0F1E5",
                    "& .MuiOutlinedInput-input::placeholder": {
                      color: "black",
                      fontWeight: "400",
                      alignSelf: "center",
                    },
                  }}
                  type="text"
                  name="subject"
                  placeholder="Subject"
                  label={t("formph_subject")}
                  required
                />

                <TextField
                  fullWidth
                  sx={{
                    width: "100%",
                    backgroundColor: "#E0F1E5",
                    "& .MuiOutlinedInput-input::placeholder": {
                      color: "black",
                      fontWeight: "400",
                      alignSelf: "center",
                    },
                  }}
                  type="text"
                  name="message"
                  placeholder="Message"
                  label={t("formph_message")}
                  required
                />
              </Stack>
            </div>
            <div className="btnForm">
              <Button
                variant="contained"
                sx={{
                  color: "white",
                  padding: "10px 60px",
                  borderRadius: "10px",
                  fontWeight: "400",
                  textTransform: "inherit",
                  border: "none",
                  background:
                    "linear-gradient(90deg, rgba(46,132,70,1) 0%, rgba(0,100,0,1) 100%);",
                }}
                type="submit"
              >
                Submit
              </Button>
            </div>
          </form>

          <div className="map" data-aos="fade-right">
            {" "}
            <iframe
              title="UdyamWell Map"
              loading="lazy"
              allowFullScreen
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3430.0415871702133!2d76.9991529!3d31.7820149!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3904fb3100000001%3A0x4d14f89595415c90!2sUdyamWell%20Private%20Limited%20-%20ONDC%20Protocol%20Powered!5e0!3m2!1sen!2sin!4v1649727534574!5m2!1sen!2sin"
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;